import React from "react";
import {MapView} from "../views/MapView";
import {Route} from "react-router-dom";
import {LocationListView} from "../views/LocationListView";
import {LocationDetailView} from "../views/LocationDetailView";
import {SidebarContainer} from "../atoms/layout/SidebarContainer";
import {PageHeader} from "../atoms/PageHeader";
import {MessageDisplayLG} from "../atoms/Messages";
import styles from "./LocationPage.module.scss"
import {Hide} from "../atoms/Hide";
import {HideDesktop} from "../atoms/layout/Hide";

export const LocationPage = () => {
    return (
        <div className={styles.page}>

            <PageHeader>
                <MessageDisplayLG text={"Orte"}/>
            </PageHeader>

            <SidebarContainer sidebar={
                <Route
                    path={"/orte"}
                    component={LocationListView}
                />
            }>

                <div className={styles.content}>
                    <Route
                        path={"/orte/:location_slug"}
                        children={MapView}
                    />
                </div>

                <Route
                    path={"/orte/:location_slug"}
                    exact
                    component={LocationDetailView}
                />
            </SidebarContainer>

            <HideDesktop>
            <Route
                path={"/orte"}
                component={LocationListView}
            />
            </HideDesktop>

        </div>
    )
}