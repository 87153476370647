import React from "react";
import styles from "./Icon.module.scss";
import {ReactComponent as Like} from "../../../icons/Heart.svg";
import {ReactComponent as Search} from "../_svg/Icons/Icon-Search.svg";
import {ReactComponent as Close} from "../_svg/Icons/Icon-Close.svg";
import {ReactComponent as IconArrW_SVG} from "../_svg/Icons/Icon-SResize.svg";

export const IconWrapper: React.FC =
    props => <div {...props} className={styles.wrapper}/>


export const Icon: React.FC<React.ImgHTMLAttributes<any>> =
    ({src, alt, ...props}) =>
        <img
            src={src}
            alt={alt} {...props}
            className={styles.image}
        />;


export const IconLike: React.FC<{ active: boolean }> =
    ({active}) =>
        <Like
            className={`${styles.iconLike} ${active ? styles.liked : ""}`}
        />


export const IconArrW: React.FC =
    () =>
        <IconArrW_SVG
            className={`${styles.iconArrW}`}
        />

export const IconSearch: React.FC =
    () =>
        <Search/>

export const IconClose: React.FC =
    () =>
        <Close/>
