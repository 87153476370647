import React, {RouteComponentProps} from "react-router-dom"
import {DataContext} from "../providers/DataProvider";
import {Redirect} from "react-router-dom"
import {LocationDetail} from "../organisms/LocationDetail";
import {useContext} from "react";

type TParams = { location_slug: string };


export const LocationDetailView = ({match}: RouteComponentProps<TParams>) => {

    const {getLocationBySlug, getEventsByLocationId, getDays} = useContext(DataContext)


    if (!match.params.location_slug) {
        return <Redirect to="/orte"/>
    }

    const location = getLocationBySlug(match.params.location_slug)
    if (location === undefined) {
        return <Redirect to="/orte"/>
    }

    const events = getEventsByLocationId(location.id);
    const days = getDays();

    return (
        <LocationDetail
            location={location}
            events={events}
            days={days}
        />
    )

}