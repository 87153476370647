import React from "react-router-dom"
import {DataContext} from "../providers/DataProvider";
import {ActListHeader} from "../organisms/ActListHeader";
import {ActList} from "../views/ActList";
import {IAct} from "../../types/types";
import {Logo} from "../atoms/Logo";


export type headerModes = "default" | "filter" | "search";

export const ActListPage = () =>
    <DataContext.Consumer>{({getActs}) => (
        <WithActs acts={getActs()}/>
    )}</DataContext.Consumer>


interface IProps {
    acts: IAct[],
}

const WithActs = ({acts}: IProps) => {

    return (
        <div>

            <Logo/>

            <ActListHeader />

            <ActList acts={acts} />

        </div>
    )
}