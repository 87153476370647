import React from "react";
import styles from "./ActThumbnail.module.scss"
import {IImage} from "../../types/types";
import {Image} from "./Image";


export const ActThumbnailWrapper: React.FC =
    ({children}) =>
    <div className={styles.wrapper}>
        {children}
    </div>


export const ActThumbnail: React.FC<{image:IImage}> =
    ({image}) =>
    <div className={styles.thumbnail}>
        <Image image={image} type={"small"}/>
    </div>