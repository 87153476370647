import React from "react"
import {IDay, IEvent, ILocation} from "../../types/types";
import styles from "./LocationDetail.module.scss";
import {BlockLink} from "../atoms/BlockLink";
import {IconClose} from "../atoms/Icon/Icon";
import {LocationDetailActs} from "../molecules/LocationDetailActs";
import {ReactComponent as Locator} from "../../icons/Locator.svg";

interface IProps {
    location: ILocation,
    events: IEvent[],
    days: IDay[],
}

export const LocationDetail = ({location, events, days}: IProps) => {
    return (
        <div className={styles.wrapper}>


            <header className={styles.header}>

                <div className={`${styles.headerCol} ${styles.headline}`}>
                    <h1>
                        {location.name}
                    </h1>
                    <div>
                        {location.indoors ? "Indoor" : "Outdoor"}
                    </div>
                </div>

                <div className={`${styles.headerCol} ${styles.back}`}>
                    <BlockLink to={"/orte"}>
                        <IconClose/>
                    </BlockLink>
                </div>

            </header>


            <div className={styles.block}>

                <div className={styles.mapLink}>
                    <a href={location.mapLink}>
                        <Locator/>
                        Auf Google Maps ansehen
                    </a>
                </div>

            </div>


            <div className={styles.events}>
                <LocationDetailActs
                    events={events}
                    days={days}
                />
            </div>


        </div>
    )
}