import React from "react";
import styles from "./ActEmbeds.module.scss";
import {IAct} from "../../types/types";
import {IconWrapper} from "../atoms/IconWrapper";
import {ReactComponent as WebsiteIcon} from "../../icons/WebsiteIcon.svg";
import {ReactComponent as InstagramIcon} from "../../icons/InstagramIcon.svg";
import {ReactComponent as FacebookIcon} from "../../icons/FacebookIcon.svg";
import {ReactComponent as YoutubeIcon} from "../../icons/YoutubeIcon.svg";
import {ReactComponent as VimeoIcon} from "../../icons/VimeoIcon.svg";


export const ActEmbeds: React.FC<{
    act: IAct,
}> = ({
          act
      }) => {

    return (
        <div className={styles.container}>


            <div className={styles.linkSection}>
                <div className={`${styles.linkSection} ${styles.block}`}>
                    <div className={`${styles.linkRow}`}>

                        {act.links?.webseite &&
                        <a href={act.links.webseite} target="_blank" className={styles.socialLink}>
                            <IconWrapper fill="none" stroke="black">
                                <WebsiteIcon/>
                            </IconWrapper>
                        </a>
                        }

                        {act.links?.instagram &&
                        <a href={act.links.instagram} target="_blank" className={styles.socialLink}>
                            <IconWrapper fill="black" stroke="none">
                                <InstagramIcon/>
                            </IconWrapper>
                        </a>
                        }

                        {act.links?.facebook &&
                        <a href={act.links.facebook} target="_blank" className={styles.socialLink}>
                            <IconWrapper fill="black" stroke="none">
                                <FacebookIcon/>
                            </IconWrapper>
                        </a>
                        }

                    </div>
                </div>
            </div>


            <div className={styles.embeds}>

                {act.links?.youtube &&
                <div className={styles.embed}>
                    <div className={styles.ratio}>
                        <iframe
                            width="100%"
                            height="100%"
                            src={act.links.youtube}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        />
                    </div>
                </div>
                }

                {act.links?.vimeo &&
                <div className={styles.embed}>
                    <div className={styles.ratio}>
                        <iframe
                            width="100%"
                            height="100%"
                            src={act.links?.vimeo}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                </div>
                }

                {act.links?.soundcloud ?
                    act.links.soundcloud.map(url =>
                        <div className={styles.embed}>
                            <iframe
                                src={url}
                                width="100%"
                                height="250"
                                frameBorder="0"
                                allow="encrypted-media"
                            />
                        </div>)
                    :
                    null
                }


            </div>


        </div>
    )
}