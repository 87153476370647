import React from "react";
import {ITimelineSlot} from "../../types/timeline-types";
import {TimelineBar} from "../atoms/TimelineBar";
import styles from "./TimelineSlot.module.scss"
import {rowHeightUnit} from "../../utils/constants";


export const TimelineSlot: React.FC<ITimelineSlot> =
    ({
         event,
         left,
         width,
         lineIndex,
     }) => {

        const style = {
            top: lineIndex * rowHeightUnit + "rem",
            width: width * 100 + "%",
            left: left * 100 + "%",
            height: rowHeightUnit + "rem"
        }

        return (
            <div className={styles.slot} style={style}>
                <TimelineBar event={event}/>
            </div>
        )

    }
