import React from "react"
import {DataContext} from "../providers/DataProvider";
import {matchPath, withRouter} from "react-router";
import {LocationListItem} from "../atoms/LocationListItem";
import styles from "./LocationListView.module.scss"
export const LocationListView = withRouter(({location}) => {

    return (
        <DataContext.Consumer>
            {
                ({getLocations}) => {
                    const locations = getLocations();
                    return (
                        <div className={styles.list}>
                            {locations.map(l => {

                                    const match = matchPath(location.pathname, {
                                        path: `/orte/${l.slug}`,
                                        exact: true,
                                        strict: false
                                    });

                                    return (
                                        <LocationListItem
                                            to={"/orte/" + l.slug}
                                            key={l.id}
                                            iconContent={l.index}
                                            text={l.name}
                                        />
                                    )
                                }
                            )}
                        </div>
                    )
                }

            }
        </DataContext.Consumer>
    )
})