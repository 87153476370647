import React from "react";
import {TimelineView} from "../views/TimelineView";
import {PageHeader} from "../atoms/PageHeader";
import {MessageDisplayLG} from "../atoms/Messages";


export const TimelinePage: React.FC = () => {

    return (
        <div className="TimelinePage">
            <PageHeader>
                <MessageDisplayLG text={"Timetable"}/>
            </PageHeader>
            <TimelineView/>
        </div>
    )

}