import styles from "./TimelineLocationLabels.module.scss"
import React from "react";
import {MessageLG} from "../atoms/Messages";
import {ITimelineRow} from "../../types/timeline-types";
import {rowHeightUnit} from "../../utils/constants";
import {Link} from "react-router-dom";
import {LocatorIcon} from "../atoms/LocatorIcon";

export const TimelineLocationLabels: React.FC<{ rows: ITimelineRow[] }> = ({rows}) => {
    return (
        <div className={styles.container}>

            {
                rows.map((row, index) => {

                    const style = {
                        height: row.height * rowHeightUnit  + "rem",
                    }

                    return (
                        <div
                            className={styles.labelRow}
                            key={index}
                        >
                            <div className={styles.spacer}/>
                            <div
                                className={styles.label + " type-lg"}
                                style={style}
                            >
                                <Link to={"/orte/" + row.location.slug}>
                                    <LocatorIcon />
                                    <MessageLG text={row.location.name}/>
                                </Link>
                            </div>
                        </div>
                    )

                })
            }
            <div className={styles.scalePlaceholder}/>

        </div>
    )
}