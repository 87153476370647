import React, {useContext, useEffect, useRef} from "react";
import {ActFilterContext} from "../providers/ActFilterProvider";
import {IconClose, IconSearch} from "../atoms/Icon/Icon";

import styles from "./SearchBar.module.scss"
import {SvgSquareConteiner} from "../atoms/Icon/SvgContainers";
import {ButtonSquare} from "../atoms/Button";
import {IconWrapper} from "../atoms/IconWrapper";
import {ReactComponent as Search} from "../../icons/Search.svg";
import {ReactComponent as Close} from "../../icons/Close.svg";


interface IProps {
    closeSearchbar?: () => void,
}


export const SearchBar: React.FC<IProps> = ({closeSearchbar}) => {

    const inputElement = useRef<HTMLInputElement>(null);
    const {changeQuery,filterSet} = useContext(ActFilterContext);


    const handleEscape = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            e.preventDefault()
            inputElement.current?.blur()
            _closeSearchbar()
        }
    }

    const _closeSearchbar = () => {
        changeQuery("")
        if (closeSearchbar) {
            closeSearchbar();
        }
    }
    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        changeQuery(e.currentTarget.value)
    }
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        inputElement.current?.blur()
    }

    useEffect(() => {
        inputElement.current?.focus()
        window.addEventListener("keyup", handleEscape)
        return () => {
            window.removeEventListener("keyup", handleEscape)
        }
    })

    return (
        <form
            className={styles.form}
            action="/acts"
            onSubmit={handleSubmit}
            autoComplete="off"
        >
            <input
                type="text"
                className={styles.input}
                name="search"
                value={filterSet.query}
                autoFocus
                onChange={handleChange}
                ref={inputElement}
            />


            <ButtonSquare
                handleClick={_closeSearchbar}
                size={4}
                rounded
            >
                <IconWrapper fill={"none"} stroke={"black"}>
                    <Close/>
                </IconWrapper>
            </ButtonSquare>


        </form>
    )
}