import React, {useState} from "react";
import {IImage} from "../../types/types";
import styles from "./Image.module.scss";

export const Image: React.FC<{
    image: IImage,
    type?: "url" | "small" | "large" | "thumb" | "panorama",
}> = ({
          image,
          type
      }) => {

    const [loaded, setLoaded] = useState(false)

    const {title, url, url_small, url_large, url_thumb, url_panorama, width, height,} = image;

    let src;

    switch (type){
        case "small":
            src = url_small;
            break;
        case "large":
            src = url_large;
            break;
        case "panorama":
            src = url_panorama;
            break;
        case "thumb":
            src = url_thumb;
            break;
        default:
            src = url;
            break;
    }
        const handleLoad = () => {
            setLoaded(true)
        }

    return (
        <img
            src={src}
            alt={title}
            width={width}
            height={height}
            className={`${styles.image} ${loaded ? styles.loaded : styles.loading}`}
            onLoad={handleLoad}
        />
    )
}