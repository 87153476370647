import React, {useContext} from "react";
import {IEvent} from "../../types/types";
import {LinkHubContext} from "../providers/LinkHubProvider";
import {DataContext} from "../providers/DataProvider";
import {Link} from "react-router-dom";
import styles from "./EventToActLink.module.scss";

interface IProps {
    event: IEvent,
    addClassNames?: string,
}


export const EventToActLink: React.FC<IProps> =
    ({
         children,
         event,
         addClassNames="",
     }) => {


        const {getActsByIds} = useContext(DataContext);
        const {setOverlay} = useContext(LinkHubContext);
        const acts = getActsByIds(event.act_ids)


        const handleClick = (_event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (acts.length > 1) {
                _event.preventDefault()
                setOverlay(event)
            }
        }


        if (!acts.length) {
            return (
                <div className={`${styles.link} ${addClassNames}`}>
                    {children}
                </div>
            )
        } else {
            return (
                <Link
                    className={`${styles.link} ${addClassNames}`}
                    to={acts.length ? "/acts/" + acts[0]?.slug : ""}
                    onClick={handleClick}
                >
                    {children}
                </Link>
            )
        }

    }

