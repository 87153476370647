import React, {useState} from "react";
import {LinkProps, NavLink} from "react-router-dom";
import styles from "./LocationListItem.module.scss"
import {LocationIndexBadge} from "./LocationIndexBadge";

export const LocationListItem: React.FC<LinkProps & {
    iconContent?: React.ReactNode,
    text: string,
}> = ({
          iconContent,
          to,
          text
      }) => {
    const [active, setActive] = useState(false)
    return (
        <NavLink
            to={to}
            isActive={(match) => {
                setActive(!!match);
                return !!match
            }}
            className={styles.container}
            activeClassName={styles.active}
        >

            {iconContent &&
            <div className={styles.iconWrapper}>
                <LocationIndexBadge active={active}>
                    {iconContent}
                </LocationIndexBadge>
            </div>
            }

            <div className={styles.textWrapper}>
                {text}
            </div>

        </NavLink>
    )
}