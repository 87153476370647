import {useEffect} from "react";
import {withRouter} from "react-router-dom"



export const LocationObserver = withRouter(
    ({location}) => {

        useEffect(() => {

            window.scrollTo(0, 0)

        }, [location.key])

        return null
    }
);