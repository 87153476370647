import React from "react"
import {ActPreviewCard} from "../molecules/ActPreviewCard";
import {GridContainer, GridRow} from "../atoms/layout/Grid";
import {IAct} from "../../types/types";
import {SidebarContainer} from "../atoms/layout/SidebarContainer";
import {ActFilterInterface} from "../organisms/ActFilterInterface";
import {ActFilterContext} from "../providers/ActFilterProvider";

interface IProps {
    acts: IAct[],
}

export const ActList = ({acts}: IProps) => {

    return (
        <SidebarContainer sidebar={
            <ActFilterInterface/>
        }>
            <GridContainer>
                <GridRow>
                    <ActFilterContext.Consumer>
                        {
                            ({filterActs}) =>
                                filterActs(acts).map(act =>
                                    <ActPreviewCard
                                        key={act.id}
                                        act={act}
                                    />
                                )

                        }
                    </ActFilterContext.Consumer>
                </GridRow>
            </GridContainer>
        </SidebarContainer>
    )
}