import React from "react-router-dom"
import styles from "./Footer.module.scss"
import {FooterNav} from "../molecules/FooterNav";
import {LogoFooter} from "../molecules/LogoFooter";

export const Footer = () =>
    <div className={styles.footer}>


        <h5 className={styles.sender}>
            Jugendkulturfestival <br/>
            Basel 1 + 2 Sept 2023
        </h5>

        <FooterNav/>

        <LogoFooter />

    </div>