import React from "react"
import styles from "./HtmlBody.module.scss"


export const HtmlBody: React.FC<{ str: string }> = ({str}) => {
    return (
        <div
            dangerouslySetInnerHTML={{__html: str}}
            className={styles.body}
        />
    )
}

