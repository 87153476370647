import React from "react"
import {MessageDisplayLG, MessageDisplaySM} from "../atoms/Messages";

interface IProps{
    amount: number
}

export const FavsHeader = ({amount}:IProps) => {
    return(
        <div>
            <h1>
                <MessageDisplayLG text="Favoriten " /><MessageDisplaySM text={amount.toString()} />
            </h1>
        </div>
    )
}