import React from "react";
import {ILocation} from "../../types/types";
import {BlockLink} from "../atoms/BlockLink";

import styles from "./TimelineRow.module.scss"
import {ITimelineDay, ITimelineRow} from "../../types/timeline-types";
import {TimelineSlot} from "./TimelineSlot";
import {rowHeightUnit} from "../../utils/constants";
import {TimelineRuler} from "./TimelineRuler";


interface IProps {
    location: ILocation,
    row: ITimelineRow,
    timeline: ITimelineDay,
}

export const TimlinRow = ({location, row, timeline}: IProps) => {

    const style = {
        height: row.height * rowHeightUnit + "rem",
    };

    return (
        <div className={styles.wrapper}>

            {/*
                mobile header for timeline rows
             */}
            <div className={styles.headerTrack}>
                <div className={styles.headerSlide}>
                    <h3>
                        <BlockLink to={"/orte/" + location.slug}>
                            {location.name}
                        </BlockLink>
                    </h3>
                </div>
            </div>

            <TimelineRuler timeline={timeline} rulerBottom={true}/>


            {/*
                row contents
             */}
            <div
                style={style}
                className={styles.row}
            >
                {row.lines.map((line, i) => {
                    return (
                        <div
                            key={i}
                            className={styles.line}
                        >
                            {line.map(slot =>
                                <TimelineSlot
                                    key={slot.event.id}
                                    {...slot}
                                />
                            )}
                        </div>
                    )
                })}
            </div>


        </div>
    )
}



