import React, {RouteComponentProps} from "react-router-dom"
import {DataContext} from "../providers/DataProvider";
import {Redirect} from "react-router-dom"
import {ActDetailView} from "../views/ActDetailView";

type TParams = { act_slug: string };


export const ActDetailPage = ({match}: RouteComponentProps<TParams>) => {

    return (
        <DataContext.Consumer>
            {({getActBySlug}) => {

                if (!match.params.act_slug) {
                    return <Redirect to="/acts"/>
                }

                const act = getActBySlug(match.params.act_slug)

                if (act === undefined) {
                    return <Redirect to="/acts"/>
                }

                return <ActDetailView act={act} />

            }}
        </DataContext.Consumer>
    )

}