import React, {useContext, useState} from "react"
import {SearchBar} from "../molecules/SearchBar";
import {MessageDisplayLG} from "../atoms/Messages";
import {PageHeader} from "../atoms/PageHeader";
import {ButtonSquare} from "../atoms/Button";
import {IconWrapper} from "../atoms/IconWrapper";
import {ReactComponent as Search} from "../../icons/Search.svg";
import {ActFilterInterface} from "./ActFilterInterface";
import styles from "./ActListHeader.module.scss";
import {ActFilterContext} from "../providers/ActFilterProvider";

type TMode = "default" | "filter" | "search";


export const ActListHeader = () => {

    const {filterSet} = useContext(ActFilterContext);
    const [mode, setMode] = useState<TMode>(!filterSet.query?"default":"search");

    const closeSearchbar = () => {
        setMode("default")
    }
    const handleClick = () => {
        setMode("search")
    }

    return (
        <div>


            <PageHeader>
                {
                    mode === "search"
                        ? <SearchBar closeSearchbar={closeSearchbar}/>
                        : <MessageDisplayLG text={"Programm"}/>
                }
                {
                    mode !== "search" &&
                    <ButtonSquare
                        handleClick={handleClick}
                        size={4}
                        rounded
                    >
                        <IconWrapper
                            fill={"none"}
                            stroke={"black"}
                        >
                            <Search/>
                        </IconWrapper>
                    </ButtonSquare>
                }
            </PageHeader>


            <div className={styles.filters}>
                <ActFilterInterface/>
            </div>


        </div>
    )
}