import styles from "./TimlineBarContent.module.scss";
import React, {useContext} from "react"
import {ActTags} from "./ActTags";
import {IEvent} from "../../types/types";
import {DataContext} from "../providers/DataProvider";
import {HtmlString} from "./HtmlString";
import {IconWrapper} from "./IconWrapper";
import {ReactComponent as Heart} from "../../icons/Heart.svg";


export const TimelineBarContent: React.FC<{
    event: IEvent,
    isFav: boolean
}> = ({event, isFav }) => {

    const {getActsByIds} = useContext(DataContext)
    const acts = getActsByIds(event.act_ids);

    return (
        <div className={`${styles.content}`}>

            {isFav &&
            <div className={styles.icon}>
                <IconWrapper
                    fill={"white"}
                    stroke={"none"}
                >
                    <Heart/>
                </IconWrapper>
            </div>
            }

            <div className={styles.typo}>
                <div className={styles.tags}>
                    <ActTags tags={acts[0]?.tags} first/>
                </div>
                <div className={styles.title}>
                    <HtmlString str={event.title ? event.title : acts[0]?.name}/>
                </div>
            </div>

        </div>
    )
}


