import React, {useContext, useState} from "react"
import {IAct} from "../../types/types";
import {DataContext} from "./DataProvider";


interface IFilterSet {
    query: string,
    cat_ids: string[],
    day_ids: string[],
}

interface IContext {
    filterSet: IFilterSet,
    resetFilter: () => void,
    filterActs: (acts: IAct[]) => IAct[],

    changeQuery: (query: string) => void,

    toggleDay: (day_id: string) => void,
    resetDays: () => void,

    toggleCat: (cat_id: string) => void,
    resetCats: () => void,
}

const defaultFilterSet: IFilterSet = {
    query: "",
    cat_ids: [],
    day_ids: [],
}


const defaultContext: IContext = {
    filterSet: defaultFilterSet,
    resetFilter: () => {
    },
    filterActs: (acts: IAct[]) => [],

    toggleDay: (day_id: string) => {
    },
    resetDays: () => {
    },
    changeQuery: (query: string) => {
    },
    toggleCat: (cat_id: string) => {
    },
    resetCats: () => {
    },

};


export const ActFilterContext = React.createContext(defaultContext);


export const ActFilterProvider: React.FC = ({children}) => {

    const {getEventById} = useContext(DataContext);
    const [filterSet, _setFilterSet] = useState<IFilterSet>(defaultFilterSet);

    const resetFilter = () => {
        _setFilterSet(defaultFilterSet);
    }

    const filterActs = (acts: IAct[]) => {
        return acts.filter(act => {

            const testCats = (!filterSet.cat_ids.length || filterSet.cat_ids.includes(act.category_id))
            const testDays = (!filterSet.query.length || act.name.toLowerCase().includes(filterSet.query.toLowerCase()))
            const testSearch = (!filterSet.day_ids.length || filterSet.cat_ids.includes(act.category_id))


            return testCats && testDays && testSearch;

        })
            /*
            // check for events and check which event is first
             */
            .sort((a, b) => {
                const AStart = getEventById(a.events[0])?.start;
                const BStart = getEventById(b.events[0])?.start;
                if (AStart && BStart){
                    return AStart > BStart ? +1 : -1;
                }else{
                    return 0
                }
            })
    }


    const toggleCat = (cat_id: string) => {
        const isIncluded = filterSet.cat_ids.includes(cat_id);
        let nextCatIds: string[];

        if (isIncluded) {
            nextCatIds = filterSet.cat_ids.filter(id => id !== cat_id);
        } else {
            nextCatIds = [...filterSet.cat_ids, cat_id]
        }
        _setFilterSet({
            ...filterSet,
            cat_ids: nextCatIds
        })
    }
    const resetCats = () => {
        _setFilterSet({
            ...filterSet,
            cat_ids: []
        })
    }
    const changeQuery = (query: string) => {
        _setFilterSet({
            ...filterSet,
            query: query
        })
    }
    const toggleDay = (day_id: string) => {
        const isIncluded = filterSet.day_ids.includes(day_id)
        let nextDayIds: string[]

        if (isIncluded) {
            nextDayIds = filterSet.day_ids.filter(id => id !== day_id)
        } else {
            nextDayIds = [...filterSet.day_ids, day_id]
        }
        _setFilterSet({
            ...filterSet,
            day_ids: nextDayIds
        })
    }
    const resetDays = () => {
        _setFilterSet({
            ...filterSet,
            day_ids: []
        })
    }


    return (
        <ActFilterContext.Provider
            value={{
                filterSet,
                resetFilter,
                filterActs,
                changeQuery,
                toggleDay,
                resetDays,
                toggleCat,
                resetCats,
            }}>
            {children}
        </ActFilterContext.Provider>
    )
}
