import React from "react";
import {MessageSM} from "./Messages";
import {HtmlString} from "./HtmlString";

export const ActTags: React.FC<{
    tags: string[],
    first?: boolean
}> = ({
          tags,
          first
      }) => {

    let str: string | null = null;

    if (first && tags) {
        str = tags[0];
    } else if(tags){
        str = tags.join(", ");
    }

    if (str) return (
        <HtmlString str={str}/>
    )
    else return null

}