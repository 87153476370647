import React from "react";
import styles from "./LinkHubOverlay.module.scss";
import {LinkHubContext} from "../providers/LinkHubProvider";
import {CSSTransition} from "react-transition-group";
import {BlockLink} from "../atoms/BlockLink";
import {DataContext} from "../providers/DataProvider";


export const LinkHubOverlay = () => {




    return (
        <DataContext.Consumer>{({getActById}) =>
            <LinkHubContext.Consumer>
                {
                    ({ overlay,closeOverlay}) => {

                        const handleBackdropClick = (e: React.MouseEvent<HTMLElement>)=>{
                            closeOverlay()
                        }

                        return (
                            <CSSTransition
                                in={!!overlay}
                                unmountOnExit
                                mountOnEnter
                                timeout={300}
                                classNames={"fade"}
                            >
                                <div
                                    className={styles.wrapper}
                                >
                                    <div
                                        className={styles.backdrop}
                                        onClick={handleBackdropClick}
                                    />

                                    <div className={styles.inner}>

                                        <div className={styles.header}>
                                            {overlay?.title}
                                        </div>

                                        {
                                            overlay?.act_ids.map(id => {
                                                    const act = getActById(id);
                                                    if (act) return (
                                                        <BlockLink to={`/acts/${act.slug}`}>
                                                            <div className={styles.block + " text-lg"}>
                                                                {act.name}
                                                            </div>
                                                        </BlockLink>)
                                                }
                                            )
                                        }


                                        



                                    </div>
                                </div>
                            </CSSTransition>
                        )
                    }}
            </LinkHubContext.Consumer>
        }</DataContext.Consumer>
    )


}