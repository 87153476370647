import React from "react-router-dom"
import {IAct, IEvent} from "../../types/types";
import {ActTags} from "../atoms/ActTags";
import {FavoriteButton} from "../atoms/FavoriteButton";
import {FlexColGrow, FlexRow} from "../atoms/layout/FlexSystem";
import {ActEventList} from "../organisms/ActEventList";
import {BackBtn} from "../atoms/BackBtn";
import {HtmlBody} from "../atoms/HtmlBody";
import styles from "./ActDetailView.module.scss"
import {Image} from "../atoms/Image";
import {HtmlString} from "../atoms/HtmlString";
import {useContext} from "react";
import {DataContext} from "../providers/DataProvider";
import {IconWrapper} from "../atoms/IconWrapper";
import {ReactComponent as InstagramIcon} from "../../icons/InstagramIcon.svg";
import {ReactComponent as FacebookIcon} from "../../icons/FacebookIcon.svg";
import {ReactComponent as WebsiteIcon} from "../../icons/WebsiteIcon.svg";
import {ReactComponent as YoutubeIcon} from "../../icons/YoutubeIcon.svg";
import {ReactComponent as VimeoIcon} from "../../icons/VimeoIcon.svg";
import {ActEmbeds} from "../organisms/ActEmbeds";


interface IProps {
    act: IAct
}


export const ActDetailView = ({act}: IProps) => {

    const {
        name, body, image, tags, id, projekttitel, slug
    } = act;
    const {getEventById} = useContext(DataContext)


    const events = act.events.reduce((result: IEvent[], id) => {
        const event = getEventById(id)
        if (event) {
            result.push(event);
        }
        return result;
    }, [])


    return (
        <div>


            <div className={styles.back}>
                <BackBtn/>
            </div>


            <div className={styles.container}>

                <div className={`${styles.header} ${styles.block}`}>
                    <FlexRow>

                        <FlexColGrow>
                            <div className="type-display-xs">
                                <ActTags tags={tags}/>
                            </div>
                            {
                                !projekttitel ?
                                    <div>
                                        <h1 className="type-lg">
                                            <HtmlString str={name}/>
                                        </h1>
                                    </div>
                                    :
                                    <div>
                                        <h1 className="type-lg">
                                            <HtmlString str={projekttitel}/>
                                        </h1>
                                        <h3 className="type-sm">
                                            <HtmlString str={name}/>
                                        </h3>
                                    </div>
                            }
                        </FlexColGrow>

                        <FavoriteButton act_id={id}/>

                    </FlexRow>
                </div>


                <div className={styles.image}>

                    <div className={styles.image}>
                        {image && <Image image={image} type={"panorama"}/>}
                    </div>

                </div>


                <div className={styles.body}>


                    {slug !== "hiphopzoobasel" ?
                        <div className={`${styles.block} ${styles.events}`}>
                            <ActEventList events={events} listType="act"/>
                        </div>
                        : ""}


                    <div className={`${styles.block} ${styles.bodyWrapper}`}>
                        {body && <HtmlBody str={body}/>}
                        <ActEmbeds act={act}/>
                    </div>


                </div>


            </div>


        </div>
    )
}