import React from "react";
import styles from "./Hide.module.scss";

export const HideMobile: React.FC =
    ({children}) => (
        <div className={styles.hidemobile}>
            {children}
        </div>
    )

export const HideDesktop: React.FC =
    ({children}) => (
        <div className={styles.hidedesktop}>
            {children}
        </div>
    )