import React from "react";
import MapSrc from "../../static/map.svg"
import {DataContext} from "../providers/DataProvider";
import {matchPath} from "react-router";
import styles from "./MapView.module.scss"
import {RouteComponentProps} from "react-router-dom";
import {LocationPin} from "../atoms/LocationPin";


export const MapView: React.FC<RouteComponentProps<{ location_slug: string }>> =
    ({
         location,
         match
     }) => {
        return (
            <DataContext.Consumer>
                {({getLocations, getLocationBySlug}) => {

                    const locations = getLocations();
                    const isNarrow = !matchPath(location?.pathname, {path: "/orte", exact: true});

                    let off = 0;
                    if (isNarrow) {
                        const venue = getLocationBySlug(match?.params.location_slug);
                        if (venue) {
                            const {y} = venue.coordsonmap;

                            if(y > 0.1665){
                                off = 200 * y;
                            }
                            if (y > 0.8335){
                                off = 200;
                            }
                        }
                    }


                    return (
                        <div className={`${styles.container} ${isNarrow ? styles.narrow : ""}`}>
                            <div
                                className={styles.stage}
                                style={{top: isNarrow ? `-${off}%` : "",}}
                            >
                                {
                                    locations.map(
                                        l => {
                                            const match = matchPath(
                                                location.pathname,
                                                {
                                                    path: `/orte/${l.slug}`,
                                                    exact: true,
                                                    strict: false
                                                }
                                            )
                                            return (
                                                <LocationPin
                                                    key={l.id}
                                                    location={l}
                                                    active={!!match}
                                                />
                                            )
                                        }
                                    )
                                }
                                <img
                                    className={styles.image}
                                    src={MapSrc}
                                    alt=""
                                    width="100%"
                                />
                            </div>
                        </div>
                    )

                }}</DataContext.Consumer>
        )
    }