import React, {useEffect, useState} from 'react';
import Routing from "./Routing";
import {DataProvider} from "./components/providers/DataProvider";
import {FavoritesProvider} from "./components/providers/FavoritesProvider";
import {LinkHubProvider} from "./components/providers/LinkHubProvider";
import {BrowserRouter as Router} from "react-router-dom";
import {IData} from "./types/types";
import {TimelineProvider} from "./components/providers/TimelineProvider";
import {CSSTransition} from "react-transition-group";
import {Loader} from "./components/molecules/Loader";
import {TimelineTrackProvider} from "./components/providers/TimelineTrackProvider";

const App: React.FC = () => {


    const [data, setData] = useState<IData | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<any>(null)


    // check if is development env
    const dataUrl = process.env.NODE_ENV === "development" ? "/jkfprogramm.json" : "https://www.jkf.ch/programm/index.php" ;


    useEffect(() => {

        fetch(dataUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    setData(result);
                    setLoading(false);
                },
                (error) => {
                    console.log(error);
                    setError(error);
                    setLoading(false);
                }
            )

    }, [])


    return (
        <div className="App">

            <CSSTransition
                in={loading}
                timeout={700}
                classNames={"fade-700"}
                unmountOnExit
                mountOnEnter
            >
                <div>
                    <Loader/>
                </div>
            </CSSTransition>

            <CSSTransition
                in={error}
                timeout={700}
                classNames={"fade-700"}
                unmountOnExit
                mountOnEnter
            >
                <div>
                    <Loader error/>
                </div>
            </CSSTransition>

            <CSSTransition
                in={!loading && !error}
                timeout={700}
                classNames={"fade-700"}
                unmountOnExit
                mountOnEnter
            >
                <div>
                    <Router>
                        <DataProvider data={data}>
                            <TimelineProvider>
                                <TimelineTrackProvider>
                                    <FavoritesProvider>
                                        <LinkHubProvider>
                                            <Routing/>
                                        </LinkHubProvider>
                                    </FavoritesProvider>
                                </TimelineTrackProvider>
                            </TimelineProvider>
                        </DataProvider>
                    </Router>
                </div>
            </CSSTransition>

        </div>
    );


}

export default App;
