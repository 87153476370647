import React from "react";
import {format, parseISO} from "date-fns";
import {de} from 'date-fns/locale'

interface IProps {
    date: string,
    showTime?: boolean,
}

export const DateFormatted = ({date, showTime}: IProps) => {

    if (date === "0000-00-00T00:00:00" || !date){
        return <span>----</span>
    }
        const formatStr = showTime ? 'H:mm' : 'iiii d. LLL.';

    const newDate = parseISO(date);

    const formattedDate = format(newDate, formatStr, {locale: de})

    return <span>{formattedDate}</span>

}