import React from "react";
import styles from "./Button.module.scss"

export const ButtonSquare: React.FC<{
    handleClick?: React.MouseEventHandler,
    disabled?: boolean,
    size: number,
    rounded?: boolean,
}> = ({
          handleClick,
          disabled,
          size,
          children,
          rounded
      }) => {

    const style = {
        width: `${size}rem`,
        height: `${size}rem`,
        borderRadius: `${size ? size * .5 : ""}rem`,
    }

    return (
        <button
            disabled={disabled}
            type={"button"}
            className={`
                ${styles.button} 
                ${styles.square} 
                `}
            style={style}
            onClick={handleClick ? handleClick : undefined}
        >
            <div className={styles.inner}>
                {children}
            </div>
        </button>
    )
}


export const ButtonBlock: React.FC<{
    handleClick?: React.MouseEventHandler,
    disabled?: boolean,
    size: number,
    rounded?: boolean,
    label: React.ReactNode,
}> = ({
          handleClick,
          disabled,
          size,
          children,
          rounded,
          label,
      }) => {

    const style = {}

    return (
        <button
            disabled={disabled}
            type={"button"}
            className={`
                ${styles.button} 
                ${styles.block} 
                ${rounded ? styles.rounded : ""}
                `}
            style={style}
            onClick={handleClick ? handleClick : undefined}
        >
            {children}
            <div className={styles.label}>
            {label}
            </div>
        </button>
    )
}