import styles from "./TimelineBar.module.scss";
import React, {useContext, useEffect, useRef, useState} from "react"
import {EventToActLink} from "../molecules/EventToActLink";
import {IEvent} from "../../types/types";
import {FavoritesContext} from "../providers/FavoritesProvider";
import {DataContext} from "../providers/DataProvider";
import {MarqueeWrapper} from "./MarqueeWrapper";
import {TimelineBarContent} from "./TimlineBarContent";


export const TimelineBar: React.FC<{
    event: IEvent,
}> = ({
          event,
      }) => {

    const {getActsByIds} = useContext(DataContext)
    const acts = getActsByIds(event.act_ids);

    const {checkFavorite} = useContext(FavoritesContext)
    let isFav = false;
    if (acts) {
        isFav = checkFavorite(acts.map(a => a.id));
    }

    const outerRef = useRef<HTMLDivElement>(null);
    const [isMarquee, setIsMarquee] = useState<boolean>(false);


    useEffect(() => {
        if (outerRef.current) {
            const clientWidth = outerRef.current?.clientWidth
            const scrollWidth = outerRef.current?.scrollWidth
            if (clientWidth < scrollWidth) {
                setIsMarquee(true);
            }
        }
    }, [])


    return (
        <EventToActLink
            event={event}
            addClassNames={`${styles.stage} ${isFav ? styles.fav : ""}`}
        >

            <div
                className={styles.track}
                ref={outerRef}
            >

                <MarqueeWrapper wrap={isMarquee}>
                    <TimelineBarContent event={event} isFav={isFav}/>
                </MarqueeWrapper>

            </div>


        </EventToActLink>
    )
}


