import React from "react";
import styles from "./ActFilterInterface.module.scss"
import {DataContext} from "../providers/DataProvider";
import {ActFilterContext} from "../providers/ActFilterProvider";
import {ReactComponent as Close} from "../../icons/Close.svg";
import {IconWrapper} from "../atoms/IconWrapper";
import {HtmlString} from "../atoms/HtmlString";


export const ActFilterInterface = () => {


    return (
        <ActFilterContext.Consumer>{({toggleCat, filterActs, resetFilter, filterSet}) =>
            <DataContext.Consumer>
                {({getCategories}) => {

                    const handleClick = (cat_id: string) => {
                        toggleCat(cat_id)
                    }

                    return (
                        <div className={styles.container}>
                            {
                                getCategories().map(
                                    cat => (
                                        <button
                                            key={cat.id}
                                            type="button"
                                            className={`${styles.button} ${filterSet.cat_ids.includes(cat.id) ? styles.activeButton : ""}`}
                                            onClick={() => {
                                                handleClick(cat.id)
                                            }}
                                        >
                                            <HtmlString str={cat.name} />
                                        </button>
                                    )
                                )
                            }

                            {
                                filterSet.cat_ids.length ?
                                    <div className={styles.reset}>

                                        <button
                                            className={styles.resetBtn}
                                            onClick={resetFilter}
                                        >
                                            <IconWrapper fill={"none"} stroke={"black"}>
                                                <Close/>
                                            </IconWrapper>
                                            <div className={styles.resetBtnLabel}>
                                                Alle
                                            </div>
                                        </button>

                                    </div> : null
                            }
                        </div>
                    )
                }}
            </DataContext.Consumer>
        }</ActFilterContext.Consumer>

    )
}