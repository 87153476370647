import React, {useContext, useEffect, useRef, useState} from "react";
import styles from "./TimelineTrack.module.scss";
import {msToPx} from "../../utils/constants";
import ScrollContainer from "react-indiana-drag-scroll";
import {ITimelineDay} from "../../types/timeline-types";
import {TimelineRowList} from "./TimelineRowList";
import {TimelineTrackContext} from "../providers/TimelineTrackProvider";


export const TimelineTrack: React.FC<{
    timeline: ITimelineDay,
}> = ({
          timeline
      }) => {

    const stage = useRef<HTMLDivElement>(null)
    const style = {
        width: timeline.length * msToPx + "px"
    }

    const [scrollLeft, setScrollLeft] = useState(0);
    const {setOffset, getOffset} = useContext(TimelineTrackContext);

    const handleScrollEnd = () => {
        if (stage?.current?.scrollLeft) {
            setScrollLeft(stage?.current?.scrollLeft)
            setOffset(timeline.day.slug, stage?.current?.scrollLeft)
        }
    }

    useEffect(() => {
        const off = getOffset(timeline.day.slug);
        if (off && stage.current) {
            stage.current.scrollTo(off, 0)
        }
    }, [])

    return (
        <ScrollContainer
            hideScrollbars={false}
            className={styles.stage}
            onEndScroll={handleScrollEnd}
            innerRef={stage}
        >
            <div
                className={styles.track}
                style={style}
            >
                <TimelineRowList
                    timeline={timeline}
                />
            </div>
        </ScrollContainer>
    )
}