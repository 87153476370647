import {NavLink, NavLinkProps} from "react-router-dom";
import styles from "./NavLinkBlock.module.scss"
import React from "react";



export const NavLinkBlock:React.FC<NavLinkProps> = ({children,...props}) =>
    <NavLink
        {...props}
        className={styles.link}
        activeClassName={styles.active}
    >
        {children}
    </NavLink>


export const FavNavLinkBlock:React.FC<NavLinkProps> = ({children,...props}) =>
    <NavLink
        {...props}
        className={styles.favlink}
        activeClassName={styles.active}
    >
        {children}
    </NavLink>