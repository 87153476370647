import React from "react";
import styles from "./LogoFooter.module.scss";

export const LogoFooter = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.col}>
                <h5>Unterstützt durch:</h5>
                <div className={styles.imageWrapper}>
                    <a href="https://www.ggg-basel.ch/" target="_blank" rel="nofollow noopener">
                        <img src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/sponsor/GGG BASEL@2x.png"
                             alt="JKF 2023"/>
                    </a>
                    <a href="https://www.allschwil.ch/" target="_blank" rel="nofollow noopener">
                        <img src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/sponsor/Logo-Allschwil-white.png"
                             alt="JKF 2023"/>
                    </a>
                    <a href="https://www.cms-basel.ch/" target="_blank" rel="nofollow noopener">
                        <img src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/cms_Logo_sw.png" alt="JKF 2023"/>
                    </a>
                    <a href="https://www.riehen-tourismus.ch/" target="_blank" rel="nofollow noopener">
                        <img src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/sponsor/riehen_lebenskultur_weiss_transparent.png"
                             alt="JKF 2023"/>
                    </a>
                    <a href="https://www.jsd.bs.ch/ueber-das-departement/bereiche-abteilungen/generalsekretariat/swisslos-fonds.html"
                       target="_blank" rel="nofollow noopener">
                        <img src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/sponsor/BS SWISSLOS@2x.png"
                             alt="JKF 2023"/>
                    </a>
                    <a href="https://www.baselland.ch/politik-und-behorden/direktionen/sicherheitsdirektion/swisslos-fonds"
                       target="_blank" rel="nofollow noopener">
                        <img src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/BL_Logo_SWL_g_rgb.png"
                             alt="JKF 2023"/>
                    </a>
                    <a href="https://www.ag.ch/de/verwaltung/dfr/finanzen/swisslos-fonds-unterstuetzungsbeitraege" target="_blank"
                       rel="nofollow noopener">
                        <img src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/sponsor/SWISSLOS_Kanton_Aargau.png"
                             alt="JKF 2023"/>
                    </a>
                    <a href="https://so.ch/verwaltung/departement-des-innern/departementssekretariat/foerderung-gemeinnuetziger-projekte/swisslos-fonds/"
                       target="_blank" rel="nofollow noopener">
                        <img src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/sponsor/Swisslos_Fonds_Kt_SO.png"
                             alt="JKF 2023"/>
                    </a>
                </div>
            </div>

            <div className={styles.col}>
                <h5>Partner</h5>
                <div className={styles.imageWrapper}>
                    <a href="https://www.gally-websolutions.com/" target="_blank" rel="nofollow noopener"><img
                        src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/GALLY WEBSOLUTIONS@2x.png"
                        alt="JKF 2023" /></a>

                    <a href="https://riobasel.ch/" target="_blank" rel="nofollow noopener"><img
                        src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/RIO BASEL@2x.png" alt="JKF 2023"/></a>

                    <a href="https://www.baselfilmfestival.ch/home.html" target="_blank" rel="nofollow noopener"><img
                        src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/GFF_Logo_White.png"
                        alt="JKF 2023"/></a>

                    <a href="https://www.colourkey.ch/" target="_blank" rel="nofollow noopener">
                        <img src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/COLOURKEY@2x.png" alt="JKF 2023"/>
                    </a>
                    <a href="https://www.instagram.com/music_connection_basel/" target="_blank" rel="nofollow noopener">
                        <img src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/MC_02.png" alt="JKF 2023"/>
                    </a>
                    <a href="https://www.settelen.ch/settelen-ag" target="_blank" rel="nofollow noopener">
                        <img src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/Settelen.png" alt="JKF 2023"/>
                    </a>
                    <a href="https://www.theater-basel.ch/" target="_blank" rel="nofollow noopener">
                        <img src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/theater-basel-logo.png"
                             alt="JKF 2023"/>
                    </a>
                </div>
            </div>

            <div className={styles.col}>
                <h5>Nachhaltigkeitspartner</h5>
                <div className={styles.imageWrapper}>
                    <a href="https://www.lottner.ch/" target="_blank" rel="nofollow noopener"><img
                        src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/sponsor/Lottner.png"
                        alt="JKF 2023"/></a>
                    <a href="https://www.pickebike.ch/" target="_blank" rel="nofollow noopener"><img
                        src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/sponsor/pick-e-bike-logo.png"
                        alt="JKF 2023"/></a>
                </div>
            </div>

            <div className={styles.col}>
                <h5>Medienpartner</h5>
                <div className={styles.imageWrapper}>
                    <a href="https://radiox.ch/" target="_blank" rel="nofollow noopener"><img src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/RADIO X@2x.png" alt="JKF 2023"/></a>
                    <a href="https://www.kulturservice.ch/" target="_blank" rel="nofollow noopener"><img src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/KulturService_Logo_Farb.png" alt="JKF 2023"/></a>
                    <a href="https://www.tize.ch/jkf/" target="_blank" rel="nofollow noopener"><img src="https://www.jkf.ch/homepage/templates/jkf2021/assets/style/logos/Logo-tize.ch.png" alt="JKF 2023"/></a>
                </div>
            </div>
        </div>
    )
}