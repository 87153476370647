import {DataContext} from "../providers/DataProvider";
import {Link} from "react-router-dom";
import {IAct} from "../../types/types";
import {FavoritesContext} from "../providers/FavoritesProvider";
import {FavsHeader} from "../organisms/FavsHeader";
import {SidebarContainer} from "../atoms/layout/SidebarContainer";
import {ActListGrid} from "../molecules/ActListGrid";
import {PageHeader} from "../atoms/PageHeader";
import React from "react";
import styles from "./FavxPage.module.scss"

interface IProps {
    acts: IAct[],
}

export const FavsPage = () =>
    <DataContext.Consumer>{({getActsByIds}) => (
        <FavoritesContext.Consumer>{({favorites}) => {
            return <WithActs acts={getActsByIds(favorites)}/>
        }}</FavoritesContext.Consumer>
    )}</DataContext.Consumer>


export const WithActs = ({acts}: IProps) => {
    return (
        <div>

            <PageHeader>
                <FavsHeader amount={acts.length}/>
            </PageHeader>


            <SidebarContainer sidebar={null}>
                {acts.length > 0 ?
                    <ActListGrid acts={acts}/>
                    :
                    <div className={styles.defaultMessage}>
                        Du hast noch keine Acts als Favoriten markiert. Such dir jetzt welche aus! <br/>
                        <Link to={"/acts"}>Zum Programm</Link>
                    </div>
                }
            </SidebarContainer>


        </div>
    )
}