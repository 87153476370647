import React from "react";
import {IDay} from "../../types/types";
import styles from "./TimelineNavigation.module.scss"
import {NavLinkBlock} from "../atoms/NavLinkBlock";
import {DateFormatted} from "../atoms/DateFormatted";


interface IProps {
    days: IDay[],
}

export const TimelineNavigation = ({days}: IProps) => {

    return (
        <div className={styles.wrapper}>
            {
                days.map(day => (
                    <div
                        key={day.id}
                        className={styles.linkWrapper}
                    >
                        <NavLinkBlock to={"/timeline/" + day.slug}>
                            <span className="text-display-sm">
                                <DateFormatted date={day.start} />
                            </span>
                        </NavLinkBlock>
                    </div>
                ))
            }
        </div>
    )

}