import React from "react";
import styles from "./LocationIndexBadge.module.scss"

export const LocationIndexBadge: React.FC<{
    active?: boolean,
}> = ({
          children, active
      }) => {
    return (
        <div className={`${styles.badge} ${active ? styles.active : ""}`}>
            {children}
        </div>
    )
}