import React from "react";
import {DateFormatted} from "./DateFormatted";

interface IProps {
    start: string,
    end: string,
}

export const EventTime = ({start, end}: IProps) => {
    return <span><DateFormatted date={start} showTime/> – <DateFormatted date={end} showTime/> Uhr</span>
}