import React from "react";
import {withRouter} from "react-router-dom";
import styles from "./BackBtn.module.scss";
import {ReactComponent as ArrLeft} from "../../icons/ArrLeft.svg";
import {IconWrapper} from "./IconWrapper";

export const BackBtn = withRouter(
    ({history}) => {
        return (
            <button
                type={"button"}
                className={styles.button}
                onClick={history.goBack}>

                <IconWrapper
                    fill={"none"}
                    stroke={"black"}
                >
                    <ArrLeft/>
                </IconWrapper>

                <div className={styles.label}>Zurück</div>

            </button>
        )
    }
)