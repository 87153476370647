import React from "react";
import styles from "./Loader.module.scss";
import {ReactComponent as LogoSvg} from "../../static/logo.svg";

export const Loader: React.FC<{
    error?: boolean
}> = ({error}) => {

    return (
        <div className={styles.wrapper}>

            {
                !error &&
                    <LogoSvg />
            }

            {
                error &&
                <div className="type-display-lg">
                    :-/ <br/> Etwas ist schief gelaufen. Versuche die Seite neu zu laden.
                </div>
            }


        </div>
    )

}