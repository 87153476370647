import React from "react";
import styles from "./TimelineHead.module.scss"
import {ITimelineDay} from "../../types/timeline-types";
import {eachHourOfInterval, format, differenceInMilliseconds} from "date-fns";
import {toMiliseconds} from "../../utils/datetime-conversions";
import {msToPx} from "../../utils/constants";



export const TimelineRuler: React.FC<{
    timeline: ITimelineDay,
    rulerBottom?: boolean
}> = ({
          timeline,
          rulerBottom
      }) => {

    const {day} = timeline

    const startMs = toMiliseconds(day.start);
    const endMs = toMiliseconds(day.end);

    let hours = eachHourOfInterval({
        start: startMs,
        end: endMs
    })


    let distanceToFirstFullHour = differenceInMilliseconds(hours[0], startMs);
    const showFirstLabel = distanceToFirstFullHour >= 0;

    const stageStyle = {
        left: (distanceToFirstFullHour * msToPx),
        width: (endMs - startMs - distanceToFirstFullHour) * msToPx + "px",
    }
    const hourStyle = {
        width: 3600000 * msToPx + "px",
    }

    return (
        <div className={styles.container}>
            <div className={styles.stage} style={stageStyle}>

                {hours.map((h, i) =>
                    <div className={styles.hour} style={hourStyle}>

                        {((i === 0 && showFirstLabel) || i > 0) &&
                        <div className={styles.label}>
                            {format(h, "kk:mm")}
                        </div>
                        }

                        <div className={`${styles.ruler} ${rulerBottom?styles.rulerBottom:""}`} style={hourStyle}>
                            <div className={`${styles.unit} ${styles.full}`}/>
                            <div className={`${styles.unit} ${styles.quarter}`}/>
                            <div className={`${styles.unit} ${styles.half}`}/>
                            <div className={`${styles.unit} ${styles.quarter}`}/>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}