import React from "react";
import styles from "./FooterNav.module.scss";


export const FooterNav: React.FC<{}> = () => {

    return (
        <div className={styles.nav}>

            <div className={styles.linkWrapper}>
                <a href={"https://www.jkf.ch/de/infos"}>Infos</a>
            </div>

            <div className={styles.linkWrapper}>
                <a href={"https://www.jkf.ch/de/about"}>About</a>
            </div>

        </div>
    )

}