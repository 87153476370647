import React from "react"
import {IDay, IEvent, ILocation, IAct, ICategory} from "../../types/types";
import {IData} from "../../types/types";


const defaultContext = {
    getDays: (): IDay[] => {
        return []
    },
    getActs: (): IAct[] => {
        return []
    },

    getEventsFromDayId: (day_id: string): IEvent[] => {
        return []
    },
    getLocations: (): ILocation[] => {
        return []
    },
    getActById: (act_id: string): IAct | undefined => {
        return undefined
    },
    getActsByIds: (act_ids: string[]): IAct[] => {
        return []
    },
    getActBySlug: (act_slug: string): IAct | undefined => {
        return undefined
    },
    getCategories: (): ICategory[] => {
        return []
    },
    getActsByCatId: (category_id: string): IAct[] => {
        return []
    },
    getEventById: (event_id: string): IEvent | undefined => {
        return undefined
    },
    getLocationById: (location_id: string): ILocation | undefined => {
        return undefined
    },
    getLocationBySlug: (location_slug: string): ILocation | undefined => {
        return undefined
    },
    getEventsByLocationId: (location_id: string): IEvent[] => {
        return []
    },
    getDayById: (day_id: string): IDay | undefined => {
        return undefined
    },
}


interface IDataProvider {
    children: React.ReactNode,
    data: IData | null
}

export const DataContext = React.createContext(defaultContext);


export const DataProvider = ({children, data}: IDataProvider) => {

    if (!data) return null;

    const getDays = () => {
        return data.days
    }

    const getActs = () => {
        return data.acts
    }

    const getEventsFromDayId = (day_id: string): IEvent[] => {
        return data.events.filter(e => e.day_id === day_id)
    }

    const getLocations = () => {
        return data.locations
    }

    const getActById = (act_id: string) => {
        return data.acts.find(a => a.id === act_id);
    }

    const getActsByIds = (act_ids: string[]) => {
        return data.acts.filter(a => act_ids.includes(a.id))
    }

    const getActBySlug = (act_slug: string) => {
        return data.acts.find(a => a.slug === act_slug);
    }

    const getCategories = () => {
        return data.categories;
    }

    const getActsByCatId = (category_id: string) => {
        return data.acts.filter(a => a.category_id === category_id)
    }

    const getEventById = (event_id: string) => {
        return data.events.find(e => e.id === event_id)
    }

    const getLocationById = (location_id: string) => {
        return data.locations.find(l => l.id === location_id)
    }

    const getLocationBySlug = (location_slug: string) => {
        return data.locations.find(l => l.slug === location_slug)
    }

    const getEventsByLocationId = (location_id: string) => {
        return data.events.filter(e => e.location_id === location_id)
    }

    const getDayById = (day_id: string) => {
        return data.days.find(day => day.id === day_id)
    }

    return (
        <DataContext.Provider
            value={{
                getDays,
                getActs,
                getEventsFromDayId,
                getLocations,
                getActById,
                getActsByIds,
                getActBySlug,
                getCategories,
                getActsByCatId,
                getEventById,
                getLocationById,
                getLocationBySlug,
                getEventsByLocationId,
                getDayById
            }}>
            {children}
        </DataContext.Provider>
    )
}