import React, {useState} from "react"


interface IContext {
    favorites: string[],
    toggleFavorite: (act_id: string) => boolean,
    checkFavorite: (act_ids: string[]) => boolean,
    clearFavs: () => void,
}

interface IDataProvider {
    children: React.ReactNode,
}

const defaultContext = {
    favorites: [],
    toggleFavorite: (act_id: string) => {
        return false
    },
    checkFavorite: (act_ids: string[]) => {
        return true
    },
    clearFavs: () => {
    },
} as IContext;


export const FavoritesContext = React.createContext(defaultContext);


export const FavoritesProvider = ({children}: IDataProvider) => {


    const readLocalStateFavs = () => {
        const str = localStorage.getItem("favs");

        if (str) {
            const nextFavs = JSON.parse(str);
            if(Array.isArray(nextFavs)){
                return nextFavs
            }
        }
        return []
    }

    const [favorites, _setFavorites] = useState<string[]>(readLocalStateFavs());

    const setFavorites = (nextFavs: string[]) => {
        _setFavorites(nextFavs)
        const str = JSON.stringify(nextFavs);
        localStorage.setItem('favs', str);
    }

    const checkFavorite = (act_ids: string[]) => {
        return !!favorites.find(f => act_ids.includes(f));
    }

    const toggleFavorite = (act_id: string) => {

        const isIncluded = checkFavorite([act_id]);
        let alteredFavs = favorites;

        if (isIncluded) {
            alteredFavs = alteredFavs.filter(f => f !== act_id);
        } else {
            alteredFavs.push(act_id);
        }

        setFavorites([...alteredFavs]);

        return !isIncluded // return true if is added

    }

    const clearFavs = () => {
        setFavorites([]);
    }


    return (
        <FavoritesContext.Provider
            value={{
                favorites,
                checkFavorite,
                toggleFavorite,
                clearFavs
            }}>
            {children}
        </FavoritesContext.Provider>
    )
}
