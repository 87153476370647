import React from "react";
import styles from "./IconWrapper.module.scss";

export type TColor = "none" | "black" | "violet" | "orange" | "white";

export const IconWrapper: React.FC<{
    fill: TColor,
    stroke: TColor,
}> = ({
          children,
          stroke,
          fill
      }) => {

    let fillClass;
    switch (fill){
        case "black":
            fillClass = styles.fillBlack;
            break;
        case "violet":
            fillClass = styles.fillViolet;
            break;
        case "orange":
            fillClass = styles.fillOrange;
            break;
        case "white":
            fillClass = styles.fillWhite;
            break;
        default:
            fillClass = styles.fillNone;
            break;
    }

    let strokeClass;
    switch (stroke){
        case "black":
            strokeClass = styles.strokeBlack;
            break;
        case "violet":
            strokeClass = styles.strokeViolet;
            break;
        case "orange":
            strokeClass = styles.strokeOrange;
            break;
        case "white":
            strokeClass = styles.strokeWhite;
            break;
        default:
            strokeClass = styles.strokeNone;
            break;
    }

    return (
        <div
            className={`
            ${styles.wrapper} 
            ${fillClass} 
            ${strokeClass}
        `}
        >
            {children}
        </div>
    )
}