import React from "react";
import {ITimelineDay} from "../../types/timeline-types";
import {TimlinRow} from "./TimelineRow";


interface IProps {
    timeline: ITimelineDay,
}


export const TimelineRowList = ({timeline}: IProps) => {
    return (
        <React.Fragment>
            {
                timeline.rows.map( row =>
                    <TimlinRow
                        key={row.location.id}
                        location={row.location}
                        timeline={timeline}
                        row={row}
                    />
                )
            }
        </React.Fragment>
    )
}

