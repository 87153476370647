import React from "react";
import styles from "./LocationPin.module.scss"
import {ILocation} from "../../types/types";
import {BlockLink} from "./BlockLink";

export const LocationPin: React.FC<{
    location: ILocation,
    active?: boolean,
}> = ({
          location,
          active
      }) => (
    <div
        className={`${styles.locator} ${active ? styles.active : ""}`}
        style={{
            left: (location.coordsonmap.x * 100) + "%",
            top: (location.coordsonmap.y * 100) + "%",
            zIndex: active ? 3 : 2,
        }}
    >
        <BlockLink to={"/orte/" + location.slug}>
            <div className={styles.inner}>
                <div className={`${styles.circle}`}>
                    {location.index}
                </div>
            </div>
        </BlockLink>
    </div>
);
