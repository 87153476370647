import React, {useContext} from "react";
import {IEvent} from "../../types/types";
import styles from "./EventCard.module.scss";
import {DateFormatted} from "./DateFormatted";
import {EventTime} from "./EventTime";
import {Link} from "react-router-dom";
import {DataContext} from "../providers/DataProvider";
import {EventTitle} from "./EventTitle";
import {ReactComponent as Locator} from "../../icons/Locator.svg";
import {EventToActLink} from "../molecules/EventToActLink";
import {FavoritesContext} from "../providers/FavoritesProvider";
import {ReactComponent as Heart} from "../../icons/Heart.svg";

export const EventCard: React.FC<{
    event: IEvent,
    cardType: "location" | "act"
}> = ({
          event,
          cardType
      }) => {

    const {getLocationById, getDayById} = useContext(DataContext);
    const {checkFavorite} = useContext(FavoritesContext);
    const location = getLocationById(event.location_id);


    const isFav = checkFavorite(event.act_ids);
    const day = getDayById(event.day_id);


    return (
        <div className={`${styles.container} ${isFav ? styles.fav : ""}`}>


            <div className={`${styles.block} ${styles.date}`}>
                {
                    cardType !== "location" &&
                    <div>
                        <DateFormatted
                            date={day?.start ? day.start : event.start}
                        />
                    </div>
                }
                <EventTime start={event.start} end={event.end}/>
            </div>


            {(cardType !== "act") &&
            <div className={styles.block + " " + styles.titleBlock}>
                <EventToActLink event={event}>
                    <h5>
                        {isFav && <Heart/>}
                        <EventTitle event={event}/>
                    </h5>
                </EventToActLink>
            </div>
            }


            {
                ((cardType !== "location") && location) &&
                <div className={styles.block + " " + styles.locatorIcon}>
                    <Link to={"/orte/" + location?.slug}>
                        <Locator/>
                        {location.name}
                    </Link>
                </div>
            }


        </div>
    )
}