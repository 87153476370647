import React from "react"
import styles from "./SidebarContainer.module.scss"


export const SidebarContainer: React.FC<{
    sidebar: React.ReactNode
}> = ({children, sidebar}) => (
    <div className={styles.container}>

        <div className={styles.sidebar}>
            <div className={styles.inner}>
                {sidebar}
            </div>
        </div>

        <div className={styles.content}>
            {children}
        </div>

    </div>
)


export const SidebarSpacedBody: React.FC = ({children}) => (
    <div className={styles.spacedBody}>
        {children}
    </div>
)
