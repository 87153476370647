import React from "react";
import {GridColLg, GridRow} from "../atoms/layout/Grid";
import styles from "../organisms/LocationDetail.module.scss";
import {Container} from "../atoms/Container";
import {DateFormatted} from "../atoms/DateFormatted";
import {IDay, IEvent} from "../../types/types";
import {ActEventList} from "../organisms/ActEventList";

export const LocationDetailActs: React.FC<{
    events: IEvent[],
    days: IDay[],
}> = ({
          events,
          days
      }) => {

    return (
        <GridRow>
            {days.map(day => {

                const eventsForDay = events.filter(e => e.day_id === day.id);

                if (eventsForDay.length) {
                    return (
                        <GridColLg key={day.id}>
                            <div key={day.id} className={styles.day}>

                                <Container>
                                    <h3 className="type-lg">
                                        <DateFormatted date={day.start}/>
                                    </h3>
                                    <ActEventList
                                        events={eventsForDay}
                                        listType="location"
                                    />
                                </Container>

                            </div>
                        </GridColLg>
                    )
                } else {
                    return null
                }
            })}
        </GridRow>
    )

}