import React, {useContext} from "react";
import {IAct} from "../../types/types";
import {ActThumbnail, ActThumbnailWrapper} from "../atoms/ActThumbnail";
import {ActTags} from "../atoms/ActTags";
import {Link} from "react-router-dom";
import {FavoriteButton} from "../atoms/FavoriteButton";
import styles from "./ActPreviewCard.module.scss";
import {GridCol} from "../atoms/layout/Grid";
import {HtmlString} from "../atoms/HtmlString";
import {DataContext} from "../providers/DataProvider";
import {DateFormatted} from "../atoms/DateFormatted";
import {LocatorIcon} from "../atoms/LocatorIcon";


interface IProps {
    act: IAct,
}


export const ActPreviewCard: React.FC<IProps> = ({act}) => {

    const {getEventById, getLocationById, getDayById} = useContext(DataContext)

    return (
        <GridCol>
            <Link
                className={styles.container}
                to={"/acts/" + act.slug}
            >


                <ActThumbnailWrapper>
                    <ActThumbnail image={act.image}/>
                    <div className={styles.favBtnWrapper}>
                        <FavoriteButton act_id={act.id} dark/>
                    </div>
                </ActThumbnailWrapper>


                <div className={styles.info}>

                    <div className={styles.tags}>
                        <ActTags tags={act.tags}/>
                    </div>

                    <div className={styles.title}>
                        <HtmlString str={act.name}/>
                    </div>

                    {act.slug === "hiphopzoobasel" ?
                        <div className={styles.event}>
                            <div className={styles.location}>
                                <LocatorIcon/>
                                MÜNSTERPLATZ HIP-HOP ZOO
                            </div>
                        </div>
                        : act.events.map(id => {

                            const event = getEventById(id);

                            if (event) {
                                const day = getDayById(event.day_id);
                                const location = getLocationById(event.location_id);
                                return (
                                    <div className={styles.event}>
                                        {day &&
                                            <div>
                                                <DateFormatted date={day.start}/>
                                            </div>
                                        }
                                        <div>
                                            ab <DateFormatted date={event.start} showTime/> Uhr
                                        </div>
                                        <div className={styles.location}>
                                            <LocatorIcon/>
                                            {location?.name}
                                        </div>

                                    </div>
                                )
                            } else {
                                return null
                            }
                        })}

                </div>


            </Link>
        </GridCol>
    )
}