import React, {useContext} from "react";
import {IEvent} from "../../types/types";
import {DataContext} from "../providers/DataProvider";
import {HtmlString} from "./HtmlString";

export const EventTitle: React.FC<{
    event: IEvent,
}> = ({
          event
      }) => {


    /*
     * if event has title return event
     * else check if event has acts
     *   if events has acts
     *      return first acts title
     *   else return
     */


    const {getActsByIds, getActById} = useContext(DataContext);

    if (event.title) {
        return <HtmlString str={event.title}/>
    }

    const acts = getActsByIds(event.act_ids);

    if (acts[0].id) {
        const act = getActById(acts[0].id);
        if (act) {
            return <HtmlString str={act.name}/>
        }
    }

    return <span>Ohne Titel</span>

}