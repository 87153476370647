import React, {useContext, useMemo} from "react";
import {DataContext} from "./DataProvider";
import {ITimelineDay, ITimelineRow, ITimelineSlot, TTimeline} from "../../types/timeline-types";
import {toMiliseconds} from "../../utils/datetime-conversions";
import {areIntervalsOverlapping, parseISO} from "date-fns";


interface IContext {
    timelineData: TTimeline | null,
}


const defaultContext = {
    timelineData: null,
} as IContext;


export const TimelineContext = React.createContext(defaultContext);


export const TimelineProvider: React.FC =
    ({
         children
     }) => {

        const {getDays, getEventsFromDayId, getLocations} = useContext(DataContext)
        const days = getDays()

        const timelineData = useMemo(() => {

            // iterate through days
            const timeline: TTimeline = days.map((day): ITimelineDay => {

                const {start, end} = day;
                const dayStart = toMiliseconds(start)
                const dayEnd = toMiliseconds(end)
                const dayLength = dayEnd - dayStart;
                const dayEvents = getEventsFromDayId(day.id);

                let location_ids: string[] = [];
                // iterate through events of day. add all locations of the events to location_ids
                dayEvents.forEach((event) => {
                    if (!location_ids.includes(event.id)) {
                        location_ids.push(event.id)
                    }
                })
                // convert location_ids array into aray with full location data
                const locations = getLocations().filter((loc) => !location_ids.includes(loc.id))

                // create a rows array and
                const rows: ITimelineRow[] = [];


                locations.forEach(location => {

                    let lines: ITimelineSlot[][] = [[]];
                    //let slots: ITimelineSlot[] = [];

                    let rowEvents = dayEvents.filter(event => event.location_id === location.id);
                    if (rowEvents.length) {

                        rowEvents.sort((a, b) => toMiliseconds(a.start) - toMiliseconds(b.start))

                        // console.log(rowEvents)
                        // distribute slots on lines
                        rowEvents.forEach(event => {

                            const {start, end} = event;
                            const eventStart = toMiliseconds(start)
                            const eventEnd = toMiliseconds(end)
                            const eventLength = eventEnd - eventStart;
                            const beforeEvent = eventStart - dayStart;

                            // start with line 0
                            let lineIndex = 0;

                            //
                            let foundEmptySlot = false;
                            while (!foundEmptySlot) {
                                // check if slot is empty
                                const isIntersecting = !!lines[lineIndex].find(slot => {
                                    return areIntervalsOverlapping(
                                        {start: parseISO(start), end: parseISO(end)},
                                        {start: parseISO(slot.event.start), end: parseISO(slot.event.end)},
                                    )
                                })
                                // if it ist place event
                                // also stop loop
                                if (!isIntersecting) {
                                    foundEmptySlot = true;
                                    let slot: ITimelineSlot = {
                                        left: beforeEvent / dayLength,
                                        width: eventLength / dayLength,
                                        event: event,
                                        lineIndex,
                                    }
                                    lines[lineIndex].push(slot);
                                } else {
                                    lineIndex++;

                                    if (lines[lineIndex] === undefined) {
                                        lines.push([])
                                    }
                                }

                            }

                        })

                        //console.log(lines)
                        const row = {
                            location,
                            height: lines.length,
                            lines,
                        }
                        rows.push(row)
                    }
                })

                return {day, rows, length: dayLength}
            })


            return timeline
        }, [days])


        return (
            <TimelineContext.Provider
                value={{timelineData}}>
                {children}
            </TimelineContext.Provider>
        )
    }