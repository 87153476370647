import React from "react"
import {ActPreviewCard} from "./ActPreviewCard";
import {GridContainer, GridRow} from "../atoms/layout/Grid";
import {IAct} from "../../types/types";


interface IProps {
    acts: IAct[],
}


export const ActListGrid = ({acts}: IProps) => {
    return (
        <GridContainer>
            <GridRow>
                {
                    acts.map(act =>
                        <ActPreviewCard
                            key={act.id}
                            act={act}
                        />
                    )
                }
            </GridRow>
        </GridContainer>
    )
}