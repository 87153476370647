import React, {useEffect, useState} from "react"
import {IEvent} from "../../types/types";
import {RouteComponentProps, withRouter} from "react-router-dom";


interface IContext {
    overlay: IEvent | null,
    setOverlay: (event: IEvent) => void,
    closeOverlay: () => void,
}


interface IProps extends RouteComponentProps {
    children: React.ReactNode,
}


const defaultContext = {
    overlay: null,
    setOverlay: (event: IEvent) => {
    },
    closeOverlay: () => {
    },
} as IContext;


export const LinkHubContext = React.createContext(defaultContext);


export const LinkHubProvider = withRouter(
    ({location, children}: IProps) => {

        const [overlay, _setOverlay] = useState<IEvent | null>(null);


        const setOverlay = (event: IEvent) => {
            _setOverlay(event);
        }

        const closeOverlay = () => {
            _setOverlay(null);
        }

        useEffect(() => {
            closeOverlay()
        }, [location])

        const handleKey = (e:KeyboardEvent)=>{
            if(e.key === "Escape"){
                closeOverlay()
            }
        }

        useEffect(() => {
            if(overlay){
                document.documentElement.classList.add("modal-open");
                window.addEventListener("keyup", handleKey)
            }else{
                document.documentElement.classList.remove("modal-open");
                window.removeEventListener("keyup", handleKey)
            }
        }, [overlay])


        return (
            <LinkHubContext.Provider
                value={{
                    overlay,
                    setOverlay,
                    closeOverlay,
                }}>
                {children}
            </LinkHubContext.Provider>
        )
    }
)