import React from "react";
import {FavoritesContext} from "../providers/FavoritesProvider";
import {IconWrapper, TColor} from "./IconWrapper";
import {ReactComponent as Like} from "../../icons/Heart.svg";
import styles from "./FavoriteButton.module.scss";


interface IProps {
    act_id: string,
    dark?: boolean,
}

export const FavoriteButton = ({
       act_id,
       dark
   }: IProps) => {

    return (
        <FavoritesContext.Consumer>
            {({ favorites, toggleFavorite, checkFavorite}) => {

                const isFav = checkFavorite([act_id]);

                // default colors
                let fill:TColor = "none";
                let stroke:TColor = dark ? "white" : "orange";

                if(isFav){
                    fill = dark ? "orange": "orange";
                    stroke = dark ? "white" : "orange";
                }


                return (
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            toggleFavorite(act_id)
                        }}
                        className={styles.button}
                    >
                        <IconWrapper
                            fill={fill}
                            stroke={stroke}
                        >
                            <Like />
                        </IconWrapper>
                    </button>
                )
            }}
        </FavoritesContext.Consumer>
    )
}