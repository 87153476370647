import React from "react";

import styles from "./Navigation.module.scss";
import {FavNavLinkBlock, NavLinkBlock} from "../atoms/NavLinkBlock";
import {IconWrapper} from "../atoms/IconWrapper";
import {ReactComponent as Like} from "../../icons/Heart.svg";
import {matchPath} from "react-router";


export const Navigation = () => (
    <div className={styles.wrapper}>

        <div className={styles.linkWrapper}>
            <NavLinkBlock
                to={"/"}
                // @ts-ignore
                isActive={(match, location) => {
                    if (match) {
                        return true;
                    } else {
                        return matchPath(location.pathname, "/acts")
                    }
                }}
                exact
            >
                <span className="text-display-sm">Programm</span>
            </NavLinkBlock>
        </div>

        <div className={styles.linkWrapper}>
            <NavLinkBlock
                to={"/timeline"}
            >
                <span className="text-display-sm">Timetable</span>
            </NavLinkBlock>
        </div>

        <div className={styles.linkWrapper}>
            <NavLinkBlock
                to={"/orte"}
            >
                <span className="text-display-sm">Orte</span>
            </NavLinkBlock>
        </div>

        <div className={`${styles.favLinkWrapper}`}>
            <FavNavLinkBlock
                to={"/favs"}
            >
                <IconWrapper fill={"none"} stroke={"white"}>
                    <Like/>
                </IconWrapper>
            </FavNavLinkBlock>
        </div>

    </div>
)
