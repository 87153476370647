import React, {} from "react";
import Marquee from "react-fast-marquee";

export const MarqueeWrapper: React.FC<{ wrap: boolean }> =
    ({
         children,
         wrap
     }) => {

        if (wrap) {

            return (
                <Marquee
                    gradient={false}
                    delay={1.5}
                    pauseOnHover={true}
                >
                    {children}
                </Marquee>
            )

        } else {

            return (
                <React.Fragment>
                    {children}
                </React.Fragment>
            )

        }

    }