import React, {useEffect, useState} from "react";


interface ITrack {
    slug: string,
    offset: number,
}

interface IContext {
    tracks: ITrack[],
    setOffset: (slug: string, offset: number) => void,
    getOffset: (slug: string) => number | undefined,
}

const defaultContext: IContext = {
    tracks: [],
    setOffset: (slug, offset) => {
    },
    getOffset: (slug) => undefined,
}


export const TimelineTrackContext = React.createContext(defaultContext);


export const TimelineTrackProvider: React.FC = ({children}) => {

    const [tracks, setTracks] = useState<ITrack[]>([]);

    const setOffset = (slug: string, offset: number) => {
        const index = tracks.findIndex((track) => slug === track.slug)
        if (index !== -1) {
            let newTracks = tracks;
            newTracks[index].offset = offset;
            setTracks(newTracks)
        } else {
            setTracks([...tracks, {slug, offset}])
        }
    }

    const getOffset = (slug: string) => {
        const track = tracks.find((track) => slug === track.slug)
        if (track !== undefined) {
            return track.offset;
        } else {
            return undefined;
        }
    }

    useEffect(()=>{
    }, [tracks])


    return (
        <TimelineTrackContext.Provider
            value={{
                tracks,
                setOffset,
                getOffset,
            }}>
            {children}
        </TimelineTrackContext.Provider>
    )
}