import React from 'react';
import {TimelinePage} from "./components/pages/TimelinePage";
import {Switch, Route, Redirect} from "react-router-dom";
import {Navigation} from "./components/molecules/Navigation";
import {ActDetailPage} from "./components/pages/ActDetailPage";
import {ActListPage} from "./components/pages/ActListPage";
import {Footer} from "./components/organisms/Footer";
import {LocationPage} from "./components/pages/LocationPage";
import {LocationObserver} from "./utils/LocationObserver";
import {FavsPage} from "./components/pages/FavsPage";
import {LinkHubOverlay} from "./components/organisms/LinkHubOverlay";
import styles from "./components/atoms/layout/PageWrapper.module.scss"
import {ActFilterProvider} from "./components/providers/ActFilterProvider";



function Routing() {
    return (
        <div className={styles.page}>


            <LocationObserver/>
            <Navigation/>


            <ActFilterProvider>

                <Switch>

                    <Route
                        path={"/timeline"}
                        component={TimelinePage}
                    />

                    <Route
                        path={"/orte"}
                        component={LocationPage}
                    />

                    <Route
                        path={"/favs"}
                        component={FavsPage}
                    />

                    <Route
                        path={"/acts/:act_slug"}
                        exact
                        component={ActDetailPage}
                    />

                    <Route
                        path={"/"}
                        exact
                        component={ActListPage}
                    />

                    <Redirect to={"/"}/>

                </Switch>
            </ActFilterProvider>


            <Footer/>
            <LinkHubOverlay/>


        </div>
    )
}

export default Routing;
