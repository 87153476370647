import React, {useContext} from 'react';
import {DataContext} from "../providers/DataProvider";
import {TimlinRow} from "../molecules/TimelineRow";
import {Redirect, Route, Switch} from "react-router-dom";
import styles from "./TimelineView.module.scss"
import {TimelineNavigation} from "../molecules/TimelineNavigation";
import {SidebarContainer} from "../atoms/layout/SidebarContainer";
import {TimelineContext} from "../providers/TimelineProvider";
import {TimelineLocationLabels} from "../molecules/TimelineLocationLabels";
import {TimelineTrack} from "../molecules/TimelineTrack";


export const TimelineView = () => {

    const {timelineData} = useContext(TimelineContext);
    const {getDays} = useContext(DataContext);

    if (!timelineData) return null

    const days = getDays();

    return (
        <div className={styles.wrapper}>

            <SidebarContainer
                sidebar={<div className={styles.navSidebar}/>}
            >
                <TimelineNavigation days={days}/>
            </SidebarContainer>

            <div>
                <Switch>

                    <Route
                        path="/timeline"
                        exact
                        render={() => {
                            if (days[0]?.slug) {
                                const redirectUrl = "/timeline/" + days[0].slug;
                                return <Redirect to={redirectUrl}/>
                            } else {
                                return null;
                            }
                        }}
                    />

                    {
                        timelineData.map(
                            timeline => {

                                return (
                                    <Route
                                        key={timeline.day.id}
                                        path={`/timeline/${timeline.day.slug}`}
                                    >

                                        <SidebarContainer
                                            sidebar={
                                                <TimelineLocationLabels
                                                    rows={timeline.rows}
                                                />
                                            }
                                        >
                                            <TimelineTrack timeline={timeline}/>
                                        </SidebarContainer>

                                    </Route>
                                )
                            }
                        )
                    }

                    {
                        timelineData[0]?.day.slug &&
                        <Redirect
                            to={timelineData[0].day.slug}
                        />
                    }

                </Switch>
            </div>


        </div>
    )
}

