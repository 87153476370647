import React from "react";
import styles from "./ActSidebar.module.scss";
import {EventCard} from "../atoms/EventCard";
import {IEvent} from "../../types/types";


export const ActEventList: React.FC<{
    events: IEvent[],
    listType: "act" | "location"
}> = ({
          events,
          listType
      }) => {

    return (
        <div className={styles.container}>
            {
                events
                    .sort((a, b) => {
                        return a.start > b.start ? 1 : -1
                    })
                    .map(
                        event => {
                            return (
                                <div
                                    key={event.id}
                                    className={styles.item}
                                >
                                    <EventCard
                                        event={event}
                                        cardType={listType}
                                    />
                                </div>
                            )
                        }
                    )
            }
        </div>
    )
}